html, body {
  width: 100%;
  overflow: hidden;
}
html {
  height: 100%;
}
body {
  line-height: $baseLineHieght;
  font-size: $baseFontSize-sp;
  min-width: 320px;
  color: $baseFontColor;
  @include min-screen($breakpoint-sp) {
    font-size: $baseFontSize-pc;
  }
}
body, input, textarea, select, button {
  font-family: $baseFontFamily;
}
* {
  box-sizing: border-box;
}
ul,ol {
  margin: 0;
  padding: 0;
}