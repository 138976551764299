.wf-hannari { font-family: "Hannari",serif; }
.wrap-toppage {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.footer {
  position: fixed;
  top: 1;
  left: 0;
  width: 100vh;
  height: 1em;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  color: #ddd;
  z-index: 999;
  &-copy {
    margin: 0;
    font-size: 11px;
  }
}

.logo-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10rem;
  height: 10rem;
  font-weight: 100;
  font-size: 3.95rem;
  svg {
    width: 100%;
  }
}

.nav-global {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9990;
  background-color: rgba(0,0,0,0.95);
  transform: translate3d(100%,0,1px);
  transition: transform 0.45s cubic-bezier(0.59, 0.01, 0.37, 0.99);
  @include min-screen($breakpoint-sp) {
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 10;
    width: auto;
    height: auto;
    background-color: transparent;
    transform: translate3d(0,-50%,1px);
  }
}

.nav-global ul {
  position: relative;
  top: 20%;
  list-style-type: none;
  text-align: right;
  @include min-screen($breakpoint-sp) {
    top: auto;
  }
}
.nav-global .navi {
  position: relative;
  padding: 10px 20px;
  @include min-screen($breakpoint-sp) {
    padding: 0 18px 0 0;
    cursor: pointer;
    background-color: transparent;
    border-bottom: none;
  }
  .nav-title {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5rem;
    z-index: 2;
    @include min-screen($breakpoint-sp) {
      padding-right: 0;
      font-size: 0.7rem;
      color: #ababab;
    }
  }
  .nav-bg {
    display: none;
    @include min-screen($breakpoint-sp) {
      display: inline-block;
      position: absolute;
      right: 15px;
      width: 0;
      height: 100%;
      transform: translateX(1%);
      transition: 0.4s cubic-bezier(0.59, 0.01, 0.37, 0.99);
      z-index: 1;
      background-color: #000;
    }
  }
  .icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    margin-left: -15px;
    transform: scale(1);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 2;
    transition: 0.4s cubic-bezier(0.59, 0.01, 0.37, 0.99);
    background-color: #ddd;


    @include min-screen($breakpoint-sp) {
      right: 0;
      margin-left: 0;
      &.ic1,
      &.ic2,
      &.ic3,
      &.ic4,
      &.ic5,
      &.ic6,
      &.ic7 { background-color: #ababab;}
    }
  }
  @include min-screen($breakpoint-sp) {
    &:hover {
      .nav-title {
        color: #fff;

      }
      .nav-bg {
        width: 100%;
      }
      .icon {
        transform: scale(1.5);
        background-color: #000;
      }
    }
  }
}

.nav-global .logo-nav{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100px;
  margin: auto;
  @include min-screen($breakpoint-sp) {
    display: none;
  }
  svg {
    fill: #747474;
  }
}

.loading {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 10000;
  opacity: 1;
  transform: translate3d(0px, 0px, 10000px);
  transition: all 0.8s linear;
  &.is-finished {
    opacity: 0;
    .animation {
      transform: rotate(30deg);
    }
  }

  .animation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 6rem;
    height: 12.1rem;
    text-align: center;
    transition: all 0.15s linear;
    @include min-screen($breakpoint-sp) {
      width: 12rem;
      height: 24.2rem;
    }
    .num {
      overflow: hidden;
      display: block;
      position: absolute;
      left: 0;
      border: 1px solid #000;
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      z-index: 2;
      box-shadow:0px 0px 0px 1px #000000 inset;
      @include min-screen($breakpoint-sp) {
        width: 12rem;
        height: 12rem;
      }
      .bar {
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: #000;
        box-shadow:0px 0px 0px 1px #000000;
      }

      &.head {
        top: 0;
      }
      &.foot {
        top: 5.95rem;
        @include min-screen($breakpoint-sp) {
          top: 5.95*2+rem;
        }
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 9999;
  transform: translate3d(0px, 0px, 9999px);
  &__logo {
    width: 62px;
    margin: 10px 10px;
    @include min-screen($breakpoint-sp) {
      width: 82px;
    }
  }
}

.header-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 10px 12px;
  @include min-screen($breakpoint-sp) {
    display: none;
  }
  .icon-bar {
    display: block;
    position: relative;
    width: 50-24+px;
    height: 2px;
    background-color: #000;
    transition: 0.4s cubic-bezier(0.59, 0.01, 0.37, 0.99);
    &.top {
      top: 0;
      margin-top: 8px;
    }
    &.middle {
      top: 4px;
    }
    &.bottom {
      top: 8px;
    }
  }
}

.group-core-sections {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.59, 0.01, 0.37, 0.99);
}

.sec-screen {
  //position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all 0.4s cubic-bezier(0.59, 0.01, 0.37, 0.99);

  &.is-show {
    z-index: 2;
    .screen-inner {
      opacity: 1.0;
      transform: scale(1.0);
    }
  }
  .screen-inner {
    opacity: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 35px 10% 10px;
    z-index: 2;
    background-color: #fff;
    overflow: auto;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.59, 0.01, 0.37, 0.99);
    -webkit-overflow-scrolling: touch;
  }
}

.title-page {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;

  margin: 0 0 0.25em;
  font-size: 1.1rem;
  font-weight: normal;
  font-family: "Hannari",serif;
  text-align: center;
  letter-spacing: 0.2em;

}

.title-screen {
  position: relative;
  margin: -1.0em 0 1.0em;
  text-align: center;
  font-weight: normal;
  font-size: 1.25rem;
  font-family: "Hannari",serif;
  @include min-screen($breakpoint-sp) {
    margin-bottom: 1.5em;
    font-size: 1.75rem;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.2em;
    width: 100%;
    height: 1px;
    margin: auto;
    background-color: #000;
    @include min-screen($breakpoint-sp) {
      width: 50%;
    }
  }
}

.text-read,
.text-center {
  font-family: "Hannari",serif;
  margin-top: 0;
  font-size: 0.85rem;
  text-align: center;
  @include min-screen($breakpoint-sp) {
    font-size: 0.92rem;
  }
}

.cover {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 2.0rem;
  color: #fff;
  letter-spacing: -0.05em;
  transition: transform 0.3s cubic-bezier(0.59, 0.01, 0.37, 0.99);
  background-color: #000;

  &#cover-top {
    display: none;transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
  &#cover-about { transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
  &#cover-work { transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
  &#cover-project { transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
  &#cover-company { transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
  &#cover-contact { transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
  &#cover-sitemap { transform: translate3d(0,0,0) matrix(.7,0,0,.7,0,0);}
}

/* TOP */
.icon-scroll {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  padding-top: 60px;
  text-align: center;
  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-radius: 50%;
    background-color: #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb 2s infinite;
    animation: sdb 2s infinite;
    opacity: 0;
    box-sizing: border-box;
  }
}
.icon-scroll span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.icon-scroll span:nth-of-type(2) {
  top: 12px;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
.icon-scroll span:nth-of-type(3) {
  top: 24px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}
@-webkit-keyframes sdb {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* ABOUT */
.txt-about-concept {
  margin: 0 0 0.5rem;
  text-align: center;


  @include min-screen($breakpoint-sp) {
    margin: 0 0 3.0rem;
  }
}

.list-about-message {
  padding: 0;
  list-style-type: none;
  @include min-screen($breakpoint-sp) {
    padding: 0;
  }
  .item {
    margin-bottom: 1.0rem;
    &:last-child {
      margin-bottom: 0;
    }
    @include min-screen($breakpoint-sp) {
      margin-bottom: 3.0rem;
    }
  }
}
.title-about-message {
  margin: 0 0 1.0rem;
  text-align: center;
  font-weight: normal;
}
.text-about-message {
  font-size: 0.85rem;
  font-family: "Hannari",serif;
  text-align: center;
  @include min-screen($breakpoint-sp) {
    font-size: 0.92rem;
  }
}

/* WORKS*/
.list-works {
  list-style-type: none;
  &__item {
    margin-bottom: 1.5em;
    text-align: center;
    font-family: "Hannari",serif;
    font-size: 0.85rem;
    &:last-child {
      margin-bottom: 0;
    }
    @include min-screen($breakpoint-sp) {
      font-size: 0.92rem;
    }
  }
}

/* PROJECT*/
.area-project {
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.list-project {
  position: relative;
  list-style-type: none;
  &:after {
    content:" ";
    display:block;
    clear:both;
  }

  &__item {
    //float: left;
    margin: 0 5px;
    text-align: center;
    font-family: "Hannari",serif;
    @include min-screen($breakpoint-sp) {
      width: 300px;
      margin: 0 auto;
    }
    .image {
      width: 100%;
      height: 180px;
    }
    .detail {
      font-size: 12px;
      .name {
        margin: 0 0 0.5em;
      }
      .date {
        margin: 0;
      }
    }
    &.is-preparing {
      .image {
        display: table;
        border: 1px solid #000;
        vertical-align: middle;
        text-align: center;
        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}

/* COMPANY */
.table-company-info {
  font-family: "Hannari",serif;

  @media screen and (max-width: 769px) {
    display: block;
    width: 100%;
    table,tbody,tr {
      display: block;
      width: 100%;
    }
    tbody {
      th {
        float: left;
        margin-right: 1em;
        clear: both;
      }
      th[rowspan="2"] {
        width: 100%;
        margin-right: 0;
        display: block;
      }
      td[rowspan="2"] {
        clear: both;
      }
    }
  }
  margin: 0 auto;
  text-align: left;
  @include min-screen($breakpoint-sp) {
    max-width: 800px;
    border-collapse: collapse;
  }
  tbody {
    th {
      margin-bottom: 0.25em;
    }
    td {
      margin-bottom: 0.5em;
      padding-left: 1.0em;
      font-size: 0.8rem;
    }
  }
  @include min-screen($breakpoint-sp) {
    tbody {
      width: 100%;
      th {
        width: 15%;
        padding: 4px 0 2.0rem;
        vertical-align: top;
      }
      td {
        width: 35%;
        padding: 4px 20px;
        vertical-align: top;
        font-size: 0.9rem;
      }
    }
  }
}

.list-def-number {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* CONTACT */
#anc_contact {
  .area-contact-form {
    @include min-screen($breakpoint-sp) {
      max-width: 960px;
      margin: 0 auto;
    }
  }
}
.text-note--small {
  font-size: 12px;
  font-family: "Hannari",serif;
}
.list-contact-form {
  margin: 0;
  text-align: left;
  @include min-screen($breakpoint-sp) {
    overflow: hidden;
    border-top: 1px solid #000;
  }
  &__item {
    text-align: left;
    @include min-screen($breakpoint-sp) {
      overflow: hidden;
      border-bottom: 1px solid #000;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
  &__title {
    margin-bottom: 10px;
    font-size: 12px;
    font-family: "Hannari",serif;
    text-align: left;
    @include min-screen($breakpoint-sp) {
      float: left;
      width: 200px;
      margin: 0;
      padding: 10px 0;

    }
  }
  &__detail {
    margin: 0 0 10px;
    font-size: 12px;
    text-align: left;
    @include min-screen($breakpoint-sp) {
      overflow: hidden;
      padding: 10px 0;
    }
    input {
      position: relative;
      width: 100%;
      height: 30px;
      padding: 5px 5px;
      line-height: 30px;
      border: 1px solid #ddd;
      box-sizing: border-box;
      -webkit-appearance: none;
      border-radius: 0;
      font-size: 16px;
    }
    textarea {
      width: 100%;
      padding: 5px 5px;
      min-height: 100px;
      border: 1px solid #ddd;
      resize: none;
      box-sizing: border-box;
      -webkit-appearance: none;
      border-radius: 0;
      font-size: 16px;
    }
  }
}

.contact-contents {
  padding: 40px 10% 10px;
  text-align: center;
}

input:placeholder-shown,
textarea:placeholder-shown{
  color: #ddd; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ddd; }

.area-contact-btn {
  text-align: center;
}
.btn-contact {
  display: block;
  width: 100%;
  padding: 14px 10px;
  background-color: #000;
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Hannari",serif;
  text-decoration: none;
  @include min-screen($breakpoint-sp) {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    padding: 14px 30px;
    &:hover {
      opacity: 0.8;
    }
  }
  &--return {
    @extend .btn-contact;
    background-color: #eee;
    color: #000;
  }
  &--submit {
    @extend .btn-contact;
    background-color: #000;
    color: #fff;
  }
}
.text-read + .btn-contact {
  margin-top: 2.0em;
}

.list-submit-btn {
  overflow: hidden;
  list-style-type: none;
  &__item {
    float: left;
    width: 50%;
    @include min-screen($breakpoint-sp) {
      float: none;
      width: auto;
      display: inline-block;
      vertical-align: middle;
    }
    &:nth-child(2n-1) {
      padding-right: 10px;
    }
    &:nth-child(2n) {
      padding-left: 10px;
    }

  }
}


.text-center {
  text-align: center;
}


/* menu open */
.is-opened {

  .header-menu .icon-bar {
    width: 29px;
    background-color: #fff;
    &.top {
      transform: translateY(5px) rotate(-45deg);
    }
    &.middle {
      opacity: 0;
    }
    &.bottom {
      transform: translateY(-7px) rotate(45deg);
    }
  }
  
  .group-core-sections {
    .screen-inner {
      transform: translate3d(0,0,-1);
    }
    .cover {
      opacity: 1;
      z-index: 3;
      &#cover-top,
      &#cover-about,
      &#cover-work,
      &#cover-project,
      &#cover-company,
      &#cover-contact,
      &#cover-sitemap {
        color: transparent;
        transform: translate3d(0,0,0) scale(1.0);
        transition: 0s;
      }
    }
  }
  .nav-global {
    transform: translate3d(0,0,1px);
  }
}

/* scroll active */
.sec-screen.is-active {
  position: relative;
  opacity: 1;
  height: 100%;
  z-index: 1;
}

.screen-inner.is-active {

  transform: scale(1.0);
  opacity: 1.0;
}

.cover.is-cover {
  z-index: 9999;
  opacity: 1;
  &#cover-top,
  &#cover-about,
  &#cover-work,
  &#cover-project,
  &#cover-company,
  &#cover-contact,
  &#cover-sitemap {
    transform: translate3d(0,0,0) matrix(1,0,0,1,0,0);
  }
}

