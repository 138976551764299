//media query
$breakpoint-sp: 768px;

// sp
@mixin min-screen($breakpoint-sp) {
  @media screen and (min-width: $breakpoint-sp) {
    @content;
  }
}

//clearfix
@mixin clearfix {
  &::after {
    content:'';
    display:block;
    clear:both;
  }
}
//hover opacity
@mixin opHover {
  &:hover {
    opacity: 0.8;
  }
}